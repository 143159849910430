import { lazy, Suspense } from "react";
import LoadingComponent from "../helper/LoadingComponent";

// * import method different because its a lazy load import
const Content = lazy(() => import(`./Content`));
const Matrix = lazy(() => import(`./Matrix`));

export default function Landing() {
  return (
    <>
      <section
        id="home"
        className={` flex flex-wrap mt-24 space-y-10 md:mt-28 max-w-[100vw] overflow-hidden py-5 `}
      >
        {/* Content */}
        <div
          className={` basis-full md:basis-1/2 lg:basis-3/5 ps-8 md:px-10 lg:px-16 `}
        >
          {/* {returnComponentWithLazySetup(<Content />, "Processing...")} */}
          <Suspense
            fallback={
              <LoadingComponent
                loadingMsgString="Loading Landing component"
                spinnerWidthString="w-20"
                spinnerHeightString="h-20"
              />
            }
          >
            <Content />
          </Suspense>
        </div>
        {/* MAtrix */}
        <div className={` basis-full md:basis-1/2 lg:basis-2/5`}>
          {/* {returnComponentWithLazySetup(<Matrix />, "Processing...")} */}
          <Suspense
            fallback={
              <LoadingComponent
                loadingMsgString="Loading Matrix"
                spinnerWidthString="w-20"
                spinnerHeightString="h-20"
              />
            }
          >
            <Matrix />
          </Suspense>
        </div>
      </section>
    </>
  );
}
