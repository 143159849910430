import { createSlice } from "@reduxjs/toolkit";

import DataJson from "../Data.json";

export const nextGenProductsSlice = createSlice({
  name: "NextGenProducts",
  initialState: {
    robotic_Active: true,
    vrAr_Active: false,
    blockchain_Active: false,
    aiMl_Active: false,
    details: DataJson.NextGenProducts.NextGen_Robotics,
  },
  reducers: {
    toggleNextGen_ArVr: (state) => {
      if (
        state.blockchain_Active ||
        state.aiMl_Active ||
        state.robotic_Active
      ) {
        state.vrAr_Active = true;
        state.blockchain_Active = false;
        state.aiMl_Active = false;
        state.robotic_Active = false;
        state.details = DataJson.NextGenProducts.nextGen_VrAr;
      }
    },

    toggleNExtGen_BlockChain: (state) => {
      if (state.vrAr_Active || state.aiMl_Active || state.robotic_Active) {
        state.blockchain_Active = true;
        state.vrAr_Active = false;
        state.aiMl_Active = false;
        state.robotic_Active = false;
        state.details = DataJson.NextGenProducts.nextGen_Blockchain;
      }
    },

    toggleNextGen_AiMl: (state) => {
      if (
        state.vrAr_Active ||
        state.blockchain_Active ||
        state.robotic_Active
      ) {
        state.aiMl_Active = true;
        state.blockchain_Active = false;
        state.vrAr_Active = false;
        state.robotic_Active = false;
        state.details = DataJson.NextGenProducts.NextGen_AiMl;
      }
    },

    toggleNextGen_Robotic: (state) => {
      if (state.vrAr_Active || state.blockchain_Active || state.aiMl_Active) {
        state.robotic_Active = true;
        state.aiMl_Active = false;
        state.blockchain_Active = false;
        state.vrAr_Active = false;
        state.details = DataJson.NextGenProducts.NextGen_Robotics;
      }
    },
  },
});
export const {
  toggleNextGen_ArVr,
  toggleNExtGen_BlockChain,
  toggleNextGen_AiMl,
  toggleNextGen_Robotic,
} = nextGenProductsSlice.actions;

export default nextGenProductsSlice.reducer;
