import { lazy, Suspense } from "react";

import LoadingComponent from "../helper/LoadingComponent";
import { useSelector } from "react-redux";

// * import method different because its a lazy load import
const CertifiedBy = lazy(() => import(`./CertifiedBy`));
const InspiredBy = lazy(() => import(`./InspiredBy`));
const Navigation = lazy(() => import(`./Navigation`));
const Contacts = lazy(() => import(`./Contacts`));
const FooterBottom = lazy(() => import(`./FooterBottom`));
const SocialLinks = lazy(() => import(`../helper/SocialLinks`));

export default function Footer() {
  // * ***Accessing Night Mode State (Redux)***
  const borderColorWith_Shadow_xlDynamic = useSelector(
    (state) => state.setNightMode
  ).css.borderColorWith_Shadow_xl;

  return (
    <>
      <section className={` space-y-5 px-1 max-w-[100vw] overflow-hidden`}>
        <section
          className={` flex flex-wrap gap-y-5 w-full sm:w-[90%] justify-center items-start mx-auto pt-12 pb-6 border-t border-b ${borderColorWith_Shadow_xlDynamic}`}
        >
          {/* CertifiedBy */}
          <div
            className={` basis-full-Bellow-250 basis-1/2  md:basis-1/4 px-2 md:px-4 w-full `}
          >
            <Suspense
              fallback={
                <LoadingComponent
                  loadingMsgString="Loading Certified By"
                  spinnerWidthString="w-16"
                  spinnerHeightString="h-16"
                />
              }
            >
              <CertifiedBy />,
            </Suspense>
          </div>

          {/* InspiredBy */}
          <div
            className={` basis-full-Bellow-250 basis-1/2  md:basis-1/4 px-2 md:px-4`}
          >
            <Suspense
              fallback={
                <LoadingComponent
                  loadingMsgString="Loading Other Services"
                  spinnerWidthString="w-16"
                  spinnerHeightString="h-16"
                />
              }
            >
              <InspiredBy />
            </Suspense>
          </div>

          {/* Navigation */}
          <div
            className={`basis-full-Bellow-250 basis-1/2  md:basis-1/4 px-2 md:px-4 `}
          >
            <Suspense
              fallback={
                <LoadingComponent
                  loadingMsgString="Loading Navigation links"
                  spinnerWidthString="w-16"
                  spinnerHeightString="h-16"
                />
              }
            >
              <Navigation />
            </Suspense>
          </div>

          {/* Contacts */}
          <div
            className={` basis-full-Bellow-250 basis-1/2  md:basis-1/4 px-2 md:px-4 `}
          >
            <Suspense
              fallback={
                <LoadingComponent
                  loadingMsgString="Loading Contact use Info"
                  spinnerWidthString="w-16"
                  spinnerHeightString="h-16"
                />
              }
            >
              <Contacts />
            </Suspense>
          </div>

          {/* Social Links */}
          <section className={``}>
            <Suspense
              fallback={
                <LoadingComponent
                  loadingMsgString="Loading Social links"
                  spinnerWidthString="w-16"
                  spinnerHeightString="h-16"
                />
              }
            >
              <SocialLinks />
            </Suspense>
          </section>
        </section>

        {/* Footer quick links */}
        <Suspense
          fallback={
            <LoadingComponent
              loadingMsgString="Loading Footer bottom component"
              spinnerWidthString="w-16"
              spinnerHeightString="h-16"
            />
          }
        >
          <FooterBottom />
        </Suspense>
      </section>
    </>
  );
}
