import { Link, useNavigate } from "react-router-dom";
import { useScrollToTop } from "../Utility";
import { Suspense, lazy } from "react";
import LoadingComponent from "../Components/helper/LoadingComponent";
import ReactGA from "react-ga4";

// * import method different because its a lazy load import
const ContactUs = lazy(() => import(`../Components/ContactUs`));
const Button = lazy(() => import(`../Components/helper/Button`));

export default function ContactUsPage() {
  // * Google analytics setup
  // * Send pageview with a custom path
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "Home page",
  });

  // *  Scroll Component on top
  useScrollToTop();

  const navigate = useNavigate();

  // * Back previous page
  function goPreviousPage() {
    navigate(-1);
  }

  return (
    <>
      <section className={` my-20`}>
        <Suspense
          fallback={
            <LoadingComponent
              loadingMsgString="Loading Contact us Component"
              spinnerHeightString=" h-20 "
              spinnerWidthString=" w-20 "
            />
          }
        >
          <ContactUs />
        </Suspense>
        <div className={` ms-12`}>
          <Link to="" className={``}>
            <Suspense
              fallback={
                <LoadingComponent
                  loadingMsgString="Loading back button"
                  spinnerHeightString=" h-16 "
                  spinnerWidthString=" w-16 "
                />
              }
            >
              <Button
                buttonText="Back"
                buttonType="button"
                buttonWidth="w-fit"
                onClickFunc={goPreviousPage}
              />
            </Suspense>
          </Link>
        </div>
      </section>
    </>
  );
}
