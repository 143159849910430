import { Suspense, lazy } from "react";
import LoadingComponent from "../helper/LoadingComponent";
import { useScrollToTop } from "../../Utility";
import ReactGA from "react-ga4";

// * import method different because its a lazy load import
const Landing = lazy(() => import(`./Landing`));
const AboutChoosingTempled = lazy(() => import(`./AboutChoosingTempled`));
const AboutDevelopment = lazy(() => import(`./AboutDevelopment`));
const CommonBasicFunctionality = lazy(() =>
  import(`./CommonBasicFunctionality`)
);

export default function Index() {
  // * Google analytics setup
  // * Send pageview with a custom path
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: window.location.pathname,
  });

  // * When launch the page this time page start at the top
  useScrollToTop();

  return (
    <>
      <section className={` space-y-11 `}>
        <Suspense
          fallback={
            <LoadingComponent
              loadingMsgString="Loading Landing page"
              spinnerHeightString=" h-20 "
              spinnerWidthString=" w-20 "
            />
          }
        >
          <Landing />
        </Suspense>

        <Suspense
          fallback={
            <LoadingComponent
              loadingMsgString="Loading whey choosing us component please wait"
              spinnerHeightString=" h-20 "
              spinnerWidthString=" w-20 "
            />
          }
        >
          <AboutChoosingTempled />
        </Suspense>

        <Suspense
          fallback={
            <LoadingComponent
              loadingMsgString="Loading Development component please wait"
              spinnerHeightString=" h-20 "
              spinnerWidthString=" w-20 "
            />
          }
        >
          <AboutDevelopment />
        </Suspense>

        <Suspense
          fallback={
            <LoadingComponent
              loadingMsgString="Loading  Functional component please wait"
              spinnerHeightString=" h-20 "
              spinnerWidthString=" w-20 "
            />
          }
        >
          <CommonBasicFunctionality />
        </Suspense>
      </section>
    </>
  );
}
