import propTypes from "prop-types";
import { useSelector } from "react-redux";

export default function LoadingComponent({
  loadingMsgString,
  spinnerHeightString,
  spinnerWidthString,
}) {
  const textSoftColorDynamic = useSelector((state) => state.setNightMode).css
    .textSoftColor;
  const nightMode = useSelector((state) => state.setNightMode).nightMode;

  return (
    <div
      className={` ${textSoftColorDynamic} flex flex-wrap flex-col justify-center items-center space-y-3`}
    >
      <div
        className={`animate-spin rounded-full ${spinnerWidthString} ${spinnerHeightString} border-t-2 border-b-2 ${
          nightMode ? " border-white " : " border-[#151515] "
        } `}
      ></div>
      <h3 className={` text-base font-bold tracking-widest`}>
        {loadingMsgString}
      </h3>
    </div>
  );
}

LoadingComponent.propTypes = {
  loadingMsgString: propTypes.string.isRequired,
  spinnerWidthString: propTypes.string.isRequired,
  spinnerHeightString: propTypes.string.isRequired,
};
