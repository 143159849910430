import { lazy, Suspense } from "react";
import LoadingComponent from "../helper/LoadingComponent";

// * import method different because its a lazy load import
const Header = lazy(() => import(`./Header`));
const ContactForm = lazy(() => import(`./ContactForm`));

export default function ContactUs() {
  return (
    <>
      <section
        id="contact-us"
        className={` flex flex-wrap justify-center items-center gap-y-10 py-5`}
      >
        {/* Heading */}
        <div className={`  basis-full lg:basis-1/3`}>
          <Suspense fallback={"Processing header..."}>
            <Header />
          </Suspense>
        </div>

        {/* Contact form */}
        <div className={` basis-full lg:basis-2/3`}>
          <Suspense
            fallback={
              <LoadingComponent
                loadingMsgString="Loading Header of the Blog section"
                spinnerWidthString="w-16"
                spinnerHeightString="h-16"
              />
            }
          >
            <ContactForm />,
          </Suspense>
        </div>
      </section>
    </>
  );
}
