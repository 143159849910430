/**
 * Returns the CSS styles for the navigation container.
 * @returns {string} The CSS styles for the navigation container.
 */
export const navContainerCss = () => {
  const containerWidth = ` w-[90%] max-w-[90%] sm:w-[65%] sm:max-w-[65%]  md:w-[55%] md:max-w-[55%] lg:w-[60%] lg:max-w-[60%] `;
  const alignMent = ` left-[5%] right-[5%] sm:left-[17.5%] sm:right-[17.5%]  md:left-[22.5%] md:right-[22.5%]  lg:left-[20%] lg:right-[20%] `;
  return containerWidth + alignMent;
};

/**
 * Returns the CSS styles for the navigation item text with responsive settings.
 * @returns {string} The CSS styles for the navigation item text.
 */
export const navItemTextResponsiveCss = () => {
  return ` font-bold md:font-normal text-basic sm:text-xl md:text-2xl lg:text-3xl `;
};
