import propTypes from "prop-types";
import { useSelector } from "react-redux";
import { validateAndExecuteFunctionProp } from "../../Utility";
export default function Button({
  buttonText,
  buttonType,
  buttonWidth,
  onClickFunc,
  groupHoverName,
}) {
  const nightModeState = useSelector((state) => state.setNightMode).nightMode;

  return (
    <>
      <button
        onClick={() => validateAndExecuteFunctionProp(onClickFunc)}
        type={buttonType}
        className={`flex justify-center items-center text-sm  md:text-base  h-fit py-1 md:py-3 px-4 md:px-10 space-x-3 font-normal tracking-widest rounded-full bg-gradient-to-r from-[#ff8000] to-white hover:from-white hover:to-[#ff8000] saturate-150 transition-bg ease-in-out duration-500 ${
          nightModeState ? " shadow-inner " : " shadow-xl "
        } ${buttonWidth ? buttonWidth : " w-fit "} ${groupHoverName}`}
      >
        <h3>{buttonText}</h3>
        <i className="ri-arrow-right-up-line text-3xl"></i>
      </button>
    </>
  );
}
Button.propTypes = {
  buttonText: propTypes.string.isRequired,
  buttonType: propTypes.string.isRequired,
  buttonWidth: propTypes.string,
  onClickFunc: propTypes.func,
  groupHoverName: propTypes.string,
};
