// import Awards from "../Components/Awards";
// import Blog from "../Components/Blog";
import ContactUs from "../Components/ContactUs";
import Landing from "../Components/Landing";
import MainAbout from "../Components/Main_About";
import NextGenProducts from "../Components/NextGen-Products";
// import OurClients from "../Components/OurClients";
import OurOfferings from "../Components/OurOfferings";
// import Project from "../Components/Projects";
// import Reviews from "../Components/Reviews";
import ReactGA from "react-ga4";

export default function HomePage() {
  // * Google analytics setup
  // * Send pageview with a custom path
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "Home page",
  });

  return (
    <>
      <section className={` space-y-24 `}>
        <Landing />

        <NextGenProducts />
        <OurOfferings />
        {/* <Project /> */}
        {/* <Reviews /> */}
        <MainAbout />
        {/* <Awards /> */}
        {/* <OurClients /> */}
        {/* <Blog /> */}
        <ContactUs />
      </section>
    </>
  );
}
