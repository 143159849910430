import { combineReducers, configureStore } from "@reduxjs/toolkit";
import OurOfferingsReducer from "../features/ourOfferingsSlice";
import nightModeReducer from "../features/nightModeSlice";
import learnMoreReducer from "../features/learnMoreSlice";

// * redux-persist Setup (For avoiding state missing when page reload)
import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";
import { SubmitContactUsForm } from "../Services/SubmitContactUsFormService";
import nextGenProductsReducer from "../features/nextGenProductsSlice";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};
const reducer = combineReducers({
  setOurOfferings: OurOfferingsReducer,
  setNextGenProducts: nextGenProductsReducer,
  setNightMode: nightModeReducer,
  learnMore: learnMoreReducer,
  [SubmitContactUsForm.reducerPath]: SubmitContactUsForm.reducer,
});
const persistReducerVariable = persistReducer(persistConfig, reducer);

export default configureStore({
  reducer: persistReducerVariable,
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(SubmitContactUsForm.middleware),
});
