import { createSlice } from "@reduxjs/toolkit";

import DataJson from "../Data.json";

export const OurServicesSlice = createSlice({
  name: "OurServices",
  initialState: {
    products_Active: true,
    apis_Active: false,
    services_Active: false,
    details: DataJson.OurOfferings.our_Products,
  },
  reducers: {
    toggleOur_Products: (state) => {
      if (state.apis_Active || state.services_Active) {
        state.products_Active = true;
        state.apis_Active = false;
        state.services_Active = false;

        state.details = DataJson.OurOfferings.our_Products;
      }
    },

    toggleOurs_APIs: (state) => {
      if (state.products_Active || state.services_Active) {
        state.apis_Active = true;
        state.products_Active = false;
        state.services_Active = false;
        state.details = DataJson.OurOfferings.our_APIs;
      }
    },

    toggleOurServices_Active: (state) => {
      if (state.products_Active || state.apis_Active) {
        state.services_Active = true;
        state.apis_Active = false;
        state.products_Active = false;
        state.details = DataJson.OurOfferings.Our_Services;
      }
    },
  },
});
export const { toggleOur_Products, toggleOurs_APIs, toggleOurServices_Active } =
  OurServicesSlice.actions;

export default OurServicesSlice.reducer;
