import ReactGA from "react-ga4";
import { getTheLastPathName } from ".";

export function trackPageLocation() {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: getTheLastPathName(),
  });
}
export function googleAnalyticsContactUsButtonEvent() {
  ReactGA.event({
    category: "Display ContactUsForm",
    action: `ContactUS Btn Actions`,
    label: getTheLastPathName(),
    value: 1,
  });
  trackPageLocation();
}

export function trackLearnMoreButtonClick(actionTitle) {
  ReactGA.event({
    category: `Display ${actionTitle}`,
    action: `LearMore Btn Actions`,
    label: getTheLastPathName(),
    value: 1,
  });
  trackPageLocation();
}

export function trackOurOfferingsNavButtonClick(actionTitle) {
  ReactGA.event({
    category: `DisplayDetails ${actionTitle}`,
    action: `OurOfferingsNav Actions`,
    label: getTheLastPathName(),
    value: 1,
  });
  trackPageLocation();
}

export function trackSocialLinksButtonClick(actionTitle) {
  ReactGA.event({
    category: `SendTo ${actionTitle} Page`,
    action: `Social Links Action`,
    label: getTheLastPathName(),
    value: 1,
  });
  trackPageLocation();
}

export function trackFooterActions(actionTitle) {
  ReactGA.event({
    category: `SendTo ${actionTitle} Page`,
    action: `Footer Actions`,
    label: getTheLastPathName(),
    value: 1,
  });
  trackPageLocation();
}

export function trackNavBarClicks(actionTitle) {
  ReactGA.event({
    category: `Click on ${actionTitle} btn`,
    action: "Navbar Actions",
    label: getTheLastPathName(),
    value: 1,
  });
  trackPageLocation();
}
