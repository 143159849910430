import React from "react";
import { navContainerCss, navItemTextResponsiveCss } from "../Utility";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleOurServices_Active,
  toggleOur_Products,
  toggleOurs_APIs,
} from "../../../features/ourOfferingsSlice";
import { trackOurOfferingsNavButtonClick } from "../../../Utility/GoogleAnalyticsActions";

export default function NavItems() {
  const dispatch = useDispatch();

  const handleToggleForDispatch = (action) => {
    dispatch(action);
  };

  const productsActive = useSelector(
    (state) => state.setOurOfferings
  ).products_Active;

  const apis_Active = useSelector((state) => state.setOurOfferings).apis_Active;

  const services_Active = useSelector(
    (state) => state.setOurOfferings
  ).services_Active;

  return (
    <div
      className={` our-Services-Nav-Control-Container absolute -top-16  h-16 md:h-20 md:-top-20 
       flex  gap-x-1 sm:gap-x-1 ${navContainerCss()} overflow-x-auto `}
    >
      <button
        onClick={() =>
          handleToggleForDispatch(
            toggleOur_Products(),
            trackOurOfferingsNavButtonClick("Our Products")
          )
        }
        className={` flex justify-center items-center  px-2 sm:px-4 mx-auto  h-full  rounded-t-lg cursor-pointer saturate-150 ${navItemTextResponsiveCss()} ${
          productsActive
            ? " text-Brand-Color border-t-[1px] border-x-[1px] border-[#7d8488] bg-[#151515] "
            : " text-white bg-[#242424] "
        } `}
      >
        <h1>Products</h1>
      </button>

      <button
        onClick={() =>
          handleToggleForDispatch(
            toggleOurs_APIs(),
            trackOurOfferingsNavButtonClick("Our APIs")
          )
        }
        className={` flex justify-center items-center px-2 sm:px-4 mx-auto  h-full rounded-t-lg cursor-pointer ${navItemTextResponsiveCss()} ${
          apis_Active
            ? " text-Brand-Color border-t-[1px] border-x-[1px] border-[#7d8488] bg-[#151515] "
            : " text-white bg-[#242424] "
        }  `}
      >
        <h1>APIs</h1>
      </button>

      <button
        onClick={() =>
          handleToggleForDispatch(
            toggleOurServices_Active(),
            trackOurOfferingsNavButtonClick("Our Services")
          )
        }
        className={` flex justify-center items-center px-2 sm:px-4 mx-auto  h-full rounded-t-lg cursor-pointer ${navItemTextResponsiveCss()} ${
          services_Active
            ? " text-Brand-Color border-t-[1px] border-x-[1px] border-[#7d8488] bg-[#151515] "
            : " text-white bg-[#242424]"
        } `}
      >
        <h1>Services</h1>
      </button>
    </div>
  );
}
