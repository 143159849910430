import { useSelector } from "react-redux";
import AccordionContainer from "./AccordionContainer";

export default function OurOfferings() {
  // * ***Accessing Night Mode State (Redux)***
  const textHeaderColorDynamic = useSelector((state) => state.setNightMode).css
    .headerTextColor;
  return (
    <>
      <section id="our-offerings" className={` py-5`}>
        <div className={` mx-auto  w-[90%] md:w-[85%] lg:w-[90%] space-y-28 `}>
          <h1 className={` ${textHeaderColorDynamic} text-5xl  md:text-6xl `}>
            Current Offerings
          </h1>
          <AccordionContainer />
        </div>
      </section>
    </>
  );
}
