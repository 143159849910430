import React from "react";
import { navContainerCss, navItemTextResponsiveCss } from "../Utility";
import { useDispatch, useSelector } from "react-redux";
import { trackOurOfferingsNavButtonClick } from "../../../Utility/GoogleAnalyticsActions";
import {
  toggleNextGen_AiMl,
  toggleNextGen_ArVr,
  toggleNExtGen_BlockChain,
  toggleNextGen_Robotic,
} from "../../../features/nextGenProductsSlice";

export default function NavItems() {
  const dispatch = useDispatch();

  const handleToggleForDispatch = (action) => {
    dispatch(action);
  };

  const vrAr_Active = useSelector(
    (state) => state.setNextGenProducts
  ).vrAr_Active;

  const blockchain_Active = useSelector(
    (state) => state.setNextGenProducts
  ).blockchain_Active;

  const aiMl_Active = useSelector(
    (state) => state.setNextGenProducts
  ).aiMl_Active;

  const robotic_Active = useSelector(
    (state) => state.setNextGenProducts
  ).robotic_Active;

  return (
    <div
      className={` our-Services-Nav-Control-Container absolute -top-16  h-16 md:h-20 md:-top-20
       flex  gap-x-1 sm:gap-x-1 ${navContainerCss()} overflow-x-auto`}
    >
      {/* Robotic Nav Btn*/}
        <button
          onClick={() =>
            handleToggleForDispatch(
              toggleNextGen_Robotic(),
              trackOurOfferingsNavButtonClick("Our Services")
            )
          }
          className={` flex justify-center items-center px-2 sm:px-4 mx-auto  h-full rounded-t-lg cursor-pointer ${navItemTextResponsiveCss()} ${
            robotic_Active
              ? " text-Brand-Color border-t-[1px] border-x-[1px] border-[#7d8488] bg-[#151515] "
              : " text-white bg-[#242424]"
          } `}
        >
          <h1>Robotic</h1>
        </button>

      {/* VR/AR Nav Btn */}
      <button
        onClick={() =>
          handleToggleForDispatch(
            toggleNextGen_ArVr(),
            trackOurOfferingsNavButtonClick("Our Products")
          )
        }
        className={` flex justify-center items-center  px-2 sm:px-4 mx-auto  h-full  rounded-t-lg cursor-pointer saturate-150 ${navItemTextResponsiveCss()} ${
          vrAr_Active
            ? " text-Brand-Color border-t-[1px] border-x-[1px] border-[#7d8488] bg-[#151515] "
            : " text-white bg-[#242424] "
        } `}
      >
        <h1>VR/AR</h1>
      </button>

      {/* AI/ML Nav Btn */}
      <button
        onClick={() =>
          handleToggleForDispatch(
            toggleNextGen_AiMl(),
            trackOurOfferingsNavButtonClick("Our Services")
          )
        }
        className={` flex justify-center items-center px-2 sm:px-4 mx-auto  h-full rounded-t-lg cursor-pointer ${navItemTextResponsiveCss()} ${
          aiMl_Active
            ? " text-Brand-Color border-t-[1px] border-x-[1px] border-[#7d8488] bg-[#151515] "
            : " text-white bg-[#242424]"
        } `}
      >
        <h1>AI/ML</h1>
      </button>
      {/* Blockchain nav Btn */}
      <button
        onClick={() =>
          handleToggleForDispatch(
            toggleNExtGen_BlockChain(),
            trackOurOfferingsNavButtonClick("Our APIs")
          )
        }
        className={` flex justify-center items-center px-2 sm:px-4 mx-auto  h-full rounded-t-lg cursor-pointer ${navItemTextResponsiveCss()} ${
          blockchain_Active
            ? " text-Brand-Color border-t-[1px] border-x-[1px] border-[#7d8488] bg-[#151515] "
            : " text-white bg-[#242424] "
        }  `}
      >
        <h1>Blockchain</h1>
      </button>

    </div>
  );
}
