import { Suspense, lazy } from "react";
import LoadingComponent from "../helper/LoadingComponent";
import { useSelector } from "react-redux";

const About = lazy(() => import(`./About`));
const Matrix = lazy(() => import(`./Matrix`));

export default function MainAbout() {
  // * ***Accessing Night Mode State (Redux)***
  const textHeaderColorDynamic = useSelector((state) => state.setNightMode).css
    .headerTextColor;

  return (
    <>
      <section id="about" className={` gap-y-10 `}>
        <header className={` ms-3 md:ms-5`}>
          <h1 className={` ${textHeaderColorDynamic} text-5xl  md:text-6xl `}>
            About Desiify
          </h1>
        </header>
        <main className={`main_About flex flex-wrap  overflow-hidden py-5 `}>
          {/* Matrix */}
          <div className={` basis-full lg:basis-1/2 `}>
            <Suspense
              fallback={
                <LoadingComponent
                  loadingMsgString="Loading Matrix"
                  spinnerWidthString="w-16"
                  spinnerHeightString="h-16"
                />
              }
            >
              <Matrix />
            </Suspense>
          </div>

          {/* About */}
          <div className={` basis-full lg:basis-1/2 `}>
            <Suspense
              fallback={
                <LoadingComponent
                  loadingMsgString="Loading About"
                  spinnerWidthString="w-16"
                  spinnerHeightString="h-16"
                />
              }
            >
              <About />
            </Suspense>
          </div>
        </main>
      </section>
    </>
  );
}
