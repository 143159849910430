import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Store from "./app/Store";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

// * redux-persist Setup
const persistStoreVariable = persistStore(Store);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Provider store={Store}>
      <PersistGate persistor={persistStoreVariable}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
