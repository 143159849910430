// Helper function to update learn more slice the state data based on the input data
/**
 * Updates the state data for the "learnMore" slice with the provided data.
 * @param {object} state - The current state of the "learnMore" slice.
 * @param {object} learnMoreData - The data to be used for updating the state.
 */
const updateStateDataForLearnMore = (state, data) => {
  state.data.landing.heading = data.landing.heading;
  state.data.AboutChoosingTempled.heading = data.AboutChoosingTempled.heading;
  state.data.AboutChoosingTempled.paragraph_1 =
    data.AboutChoosingTempled.paragraph_1;
  state.data.AboutChoosingTempled.cardCircleDetailsArray =
    data.AboutChoosingTempled.cardCircleDetailsArray;
  state.data.AboutDevelopment.heading = data.AboutDevelopment.heading;
  state.data.AboutDevelopment.cardDetails = data.AboutDevelopment.cardDetails;
  state.data.CommonBasicFunctionality.heading =
    data.CommonBasicFunctionality.heading;
  state.data.CommonBasicFunctionality.listDetailsArray =
    data.CommonBasicFunctionality.listDetailsArray;
};

module.exports = { updateStateDataForLearnMore };
