import { createSlice } from "@reduxjs/toolkit";
import DataJson from "../Data.json";
import { updateStateDataForLearnMore } from "./Helper/learnMoreHelper";

export const learnMoreSlice = createSlice({
  name: "learnMore",
  initialState: {
    data: {
      landing: {
        heading: null,
      },

      AboutChoosingTempled: {
        heading: null,
        paragraph_1: null,
        cardCircleDetailsArray: null,
      },

      AboutDevelopment: {
        heading: null,
        cardDetails: null,
      },

      CommonBasicFunctionality: {
        heading: null,
        listDetailsArray: null,
      },
    },
  },
  reducers: {
    toggleLearnMore_LocalShopIndia: (state) => {
      const learnMoreData =
        DataJson.LearnMorePage.ourOfferings.our_Products.localShopIndia;

      // Helper function to update learn more slice the state data based on the input data
      updateStateDataForLearnMore(state, learnMoreData);
    },

    toggleLearnMore_DevelopNewWebsiteOrSoftware: (state) => {
      const learnMoreData =
        DataJson.LearnMorePage.ourOfferings.Our_Services
          .Develop_Our_Custom_Website_Or_Software;

      // Helper function to update learn more slice the state data based on the input data
      updateStateDataForLearnMore(state, learnMoreData);
    },

    toggleLearnMore_MaintenanceWebsiteOrSoftware: (state) => {
      const learnMoreData =
        DataJson.LearnMorePage.ourOfferings.Our_Services
          .Maintenance_And_Support_To_Your_Custom_Website_Or_Software;

      // Helper function to update learn more slice the state data based on the input data
      updateStateDataForLearnMore(state, learnMoreData);
    },

    toggleLearnMore_GlobalSearchApi: (state) => {
      const learnMoreData =
        DataJson.LearnMorePage.ourOfferings.Our_Apis
          .Global_Product_Details_Search;

      // Helper function to update learn more slice the state data based on the input data
      updateStateDataForLearnMore(state, learnMoreData);
    },

    toggleLearnMore_InvoiceGeneratorApi: (state) => {
      const learnMoreData =
        DataJson.LearnMorePage.ourOfferings.Our_Apis.Invoice_Generator;

      // Helper function to update learn more slice the state data based on the input data
      updateStateDataForLearnMore(state, learnMoreData);
    },

    toggleLearnMore_PinCodeDetailsApi: (state) => {
      const learnMoreData =
        DataJson.LearnMorePage.ourOfferings.Our_Apis.PIN_Code_Details;

      // Helper function to update learn more slice the state data based on the input data
      updateStateDataForLearnMore(state, learnMoreData);
    },

    toggleLearnMore_HsnListApi: (state) => {
      const learnMoreData =
        DataJson.LearnMorePage.ourOfferings.Our_Apis.HSN_List;

      // Helper function to update learn more slice the state data based on the input data
      updateStateDataForLearnMore(state, learnMoreData);
    },

    toggleLearnMore_LSIVerse: (state) => {
      const learnMoreData =
        DataJson.LearnMorePage.NextGenProducts.nextGen_VrAr.LSIVerse;

      // Helper function to update learn more slice the state data based on the input data
      updateStateDataForLearnMore(state, learnMoreData);
    },

    toggleLearnMore_payment_GateWay: (state) => {
      const learnMoreData =
        DataJson.LearnMorePage.NextGenProducts.nextGen_Blockchain
          .payment_GateWay;

      // Helper function to update learn more slice the state data based on the input data
      updateStateDataForLearnMore(state, learnMoreData);
    },

    toggleLearnMore_Face_Mask_Detection: (state) => {
      const learnMoreData =
        DataJson.LearnMorePage.NextGenProducts.nextGem_AiMl.face_Mask_Detection;

      // Helper function to update learn more slice the state data based on the input data
      updateStateDataForLearnMore(state, learnMoreData);
    },

    toggleLearnMore_Work_Helmet_Detection: (state) => {
      const learnMoreData =
        DataJson.LearnMorePage.NextGenProducts.nextGem_AiMl
          .work_Helmet_Detection;

      // Helper function to update learn more slice the state data based on the input data
      updateStateDataForLearnMore(state, learnMoreData);
    },

    toggleLearMore_Main_About: (state) => {
      const learnMoreData = DataJson.LearnMorePage.Main_About;

      // Helper function to update learn more slice the state data based on the input data
      updateStateDataForLearnMore(state, learnMoreData);
    },

    toggleLearMore_Robotic_Toy: (state) => {
      const learnMoreData = DataJson.LearnMorePage.Robotic.Robotic_Toy;

      // Helper function to update learn more slice the state data based on the input data
      updateStateDataForLearnMore(state, learnMoreData);
    },

    toggleLearMore_Automation_Toy_Cars: (state) => {
      const learnMoreData = DataJson.LearnMorePage.Robotic.Automation_Toy_Cars;

      // Helper function to update learn more slice the state data based on the input data
      updateStateDataForLearnMore(state, learnMoreData);
    },
  },
});

export const {
  toggleLearnMore_DevelopNewWebsiteOrSoftware,
  toggleLearnMore_LocalShopIndia,
  toggleLearnMore_MaintenanceWebsiteOrSoftware,
  toggleLearnMore_GlobalSearchApi,
  toggleLearnMore_InvoiceGeneratorApi,
  toggleLearnMore_PinCodeDetailsApi,
  toggleLearnMore_HsnListApi,
  toggleLearnMore_LSIVerse,
  toggleLearnMore_payment_GateWay,
  toggleLearnMore_Face_Mask_Detection,
  toggleLearnMore_Work_Helmet_Detection,
  toggleLearMore_Main_About,
  toggleLearMore_Robotic_Toy,
  toggleLearMore_Automation_Toy_Cars,
} = learnMoreSlice.actions;

export default learnMoreSlice.reducer;
