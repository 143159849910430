import { Suspense, lazy } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useScrollToTop } from "../Utility";
import LoadingComponent from "../Components/helper/LoadingComponent";

// * import method different because its a lazy load import
const OurOfferings = lazy(() => import(`../Components/OurOfferings`));
const Button = lazy(() => import(`../Components/helper/Button`));

export default function OnlyOurOfferingsPage() {
  // *  Scroll Component on top
  useScrollToTop();

  const navigate = useNavigate();

  // * Back previous page
  function goPreviousPage() {
    navigate(-1);
  }
  return (
    <>
      <section className={` space-y-20`}>
        <Suspense
          fallback={
            <LoadingComponent
              loadingMsgString="Loading Our Offering Component"
              spinnerHeightString=" h-20 "
              spinnerWidthString=" w-20 "
            />
          }
        >
          <OurOfferings />
        </Suspense>

        <div className={` ms-12`}>
          <Link to="" className={``}>
            <Suspense
              fallback={
                <LoadingComponent
                  loadingMsgString="Loading back button"
                  spinnerHeightString=" h-16 "
                  spinnerWidthString=" w-16 "
                />
              }
            >
              <Button
                buttonText="Back"
                buttonType="button"
                buttonWidth="w-fit"
                onClickFunc={goPreviousPage}
              />
            </Suspense>
          </Link>
        </div>
      </section>
    </>
  );
}
