import { Link } from "react-router-dom";
import errorImg from "../../Images/ErrorImg.png";
import { useSelector } from "react-redux";
import Button from "../helper/Button";

export default function NotFound404() {
  // * At the top of the page.
  // * Start ***Enable Dark Mode Feature***
  const { bodyBackGroundColor, textSoftColor, headerTextColor } = useSelector(
    (state) => state.setNightMode
  ).css;
  const bgCardDynamic = bodyBackGroundColor;
  const textColorDynamic = textSoftColor;
  const textHeaderColorDynamic = headerTextColor;
  return (
    <div className={`${bgCardDynamic} md:flex mt-16`}>
      <div
        className={`flex items-center justify-center w-full py-10  px-6 lg:px-8 md:w-1/2 xl:px-0 `}
      >
        <div className="max-w-md space-y-7 ">
          <div className="text-5xl font-black text-Brand-Color md:text-8xl">
            404 <br />
          </div>
          <h5 className={`${textColorDynamic} font-semibold  tracking-wider`}>
            Sorry, we couldn’t find the page you’re looking for.
          </h5>
          <div className="w-16 h-1  bg-primary md:my-6"></div>
          <h3
            className={` ${textHeaderColorDynamic} text-2xl font-bold leading-normal text-Brand-Color-Light md:text-3xl`}
          >
            Page not found{" "}
          </h3>
          <div>
            <Link to="/">
              <Button buttonText="Go Back Home" buttonType="button" />
            </Link>
          </div>
        </div>
      </div>
      <div className=" relative w-full pb-full md:flex md:pb-0 md:min-h-screen md:w-1/2">
        <div className="flex items-center w-full h-full max-w-4xl mx-auto">
          <img className="object-cover w-full" src={errorImg} alt="Error" />
        </div>
      </div>
    </div>
  );
}
