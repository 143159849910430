/**
 * Updates the navbar's class based on the scroll position of the page.
 *
 * This function checks the scroll position of the page and adds the "scrolled"
 * class to the navbar element with the ID "navbar" if the scroll position is greater than 0,
 * indicating that the page has been scrolled down. Otherwise, it removes the "scrolled" class
 * from the navbar element, indicating that the page is at the top position.
 *
 * Note: Ensure that there is an HTML element with the ID "navbar" that represents the navbar
 * to apply the class changes.
 * Add your need css style in add class first mack sure this css file are linked then you see the magic
 */
export const updateNavbarClassBasedOnScroll = () => {
  const navbar = document.getElementById("navbar");

  if (navbar) {
    if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
      navbar.classList.remove("navBar-Bg-Scrolled-Top0");
      navbar.classList.add("scrolled");
    } else {
      navbar.classList.add("navBar-Bg-Scrolled-Top0");
      navbar.classList.remove("scrolled");
    }
  }
};
