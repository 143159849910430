/**
 * Smoothly scrolls to an element in the DOM with the given ID, aligning the element at the nearest edge of the scroll container, and adding extra distance from the top of the viewport.
 * @param {string} id - The ID used to find the element in the DOM.
 */
export default function smoothScrollById(id) {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
    element.style.paddingTop = "14vh";
  }
  if (window.location.pathname !== "/") {
    setTimeout(() => {
      smoothScrollById(id);
    }, 1000);
  }
}
