import { useLayoutEffect } from "react";
import uniqid from "uniqid";

/**
 * Generates a unique ID using the uniqid library.
 *
 * @param {string} parentComponentName - The name of the parent component.
 * @param {string} componentName - The name of the component.
 * @returns {string} - The generated unique ID.
 */
export const CreateUniqId = (parentComponentName, componentName) => {
  return uniqid(parentComponentName, componentName);
};

/**
 * Check if a given number is even.
 * @param {number} number - The number to be checked.
 * @returns {boolean} Returns true if the number is even, false otherwise.
 * @throws {Error} Throws an error if the input is not a number.
 * @example
 * isEven(4); // Returns true
 * isEven(7); // Returns false
 * isEven("hello"); // Throws an error: "Input is not a number"
 */
export const isEven = (number) => {
  if (typeof number !== "number") {
    throw new Error("Input is not a number");
  }
  return number % 2 === 0;
};

/**
 * Reduce the length of a string by truncating it to a specified number of words.
 *
 * @param {number} strLength - The number of words to keep in the resulting string.
 * @param {string} str - The original string to be reduced in length.
 * @returns {string|Error} The truncated string or an error message if strLength is not a number.
 *
 * @example
 * const originalString = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.";
 * const reducedString = giveStrLengthForReduceString(10, originalString);
 *  reducedString: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed"
 *
 * const nonNumberString = giveStrLengthForReduceString("not a number", originalString);
 *  nonNumberString: "Give strLength a number"
 */
export function giveStrLengthForReduceString(strLength, str) {
  if (typeof strLength !== "number") {
    return "Give strLength a number";
  }
  const originalString = str;
  const words = originalString.split(" "); // Split the string into an array of words
  const selectedWords = words.slice(0, strLength); // Select the first 20 words
  const truncatedString = selectedWords.join(" "); // Concatenate the selected words back into a string

  return truncatedString;
}

/**
 * Validates and executes a function or an object that acts as a function.
 * If the provided prop is a function or an object, it will be executed.
 * If the prop is undefined or not a function or object, an error message is logged.
 *
 * @param {Function|Object} prop - The function or object to be validated and executed.
 * @return {any|undefined} - Returns the result of the executed function or undefined if the prop is not a function or object.
 *
 * @example
 * // Case 1: Valid function
 * const myFunction = () => { return "Hello, world!"; };
 * validateAndExecuteFunctionProp(myFunction); // Output: "Hello, world!"
 *
 * // Case 2: Valid object with a callable method
 * const myObject = {
 *   myMethod: () => { return "Hello, object!"; }
 * };
 * validateAndExecuteFunctionProp(myObject.myMethod); // Output: "Hello, object!"
 *
 * // Case 3: Invalid prop
 * const myValue = 42;
 * validateAndExecuteFunctionProp(myValue); // Output: "Given onclick value not a function object"
 */
export function validateAndExecuteFunctionProp(prop) {
  if (prop !== undefined) {
    if (typeof prop === "function" || typeof prop === "object") {
      console.log(`Given onclick value type is:-  ${typeof prop}`);

      return prop(); // Execute the function
    }
    return console.log(`Given onclick value not a function  ${typeof prop}`);
  }
}

/**
 * Checks if a value is not null or undefined and returns the value,
 * otherwise, returns the provided error message followed by the value.
 *
 * @param {*} value - The value to be checked for null or undefined.
 * @param {string} errorMsg - The error message to be returned if the value is null or undefined.
 * @returns {*} If the value is not null or undefined, returns the value.
 * If the value is null or undefined, returns the error message followed by the value.
 * @example
 * // Example 1
 * const result1 = checkNullAndReturn("Hello", "Value is null or undefined:");
 * // result1 will be "Hello"
 *
 * // Example 2
 * const result2 = checkNullAndReturn(null, "Value is null or undefined:");
 * // result2 will be "Value is null or undefined: null"
 *
 * // Example 3
 * const result3 = checkNullAndReturn(undefined, "Value is null or undefined:");
 * // result3 will be "Value is null or undefined: undefined"
 */
export function checkNullAndReturn(value, errorMsg) {
  if (value !== null && value !== undefined) {
    return value;
  }
  return errorMsg + " " + value;
}

// * Open Link: Top of Page Essential
/**
 * Scrolls the page smoothly to the top when this hook is invoked.
 * @function useScrollToTop
 * @returns {void}
 * @example
 * // In a functional component:
 * import { useScrollToTop } from './useScrollToTop'; // Replace './useScrollToTop' with the actual path to the file containing the hook.
 * const MyComponent = () => {
 *   useScrollToTop(); // When this component mounts, the page will scroll smoothly to the top.
 * };
 */

export const useScrollToTop = () => {
  return useLayoutEffect(() => {
    const scrollToTop = () => {
      const c = window.pageYOffset;
      if (c > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, c - c / 8);
      }
    };
    scrollToTop();
  });
};

// * page go to beginning without useLayoutEffect
/**
 * Scrolls the page to the beginning smoothly.
 *
 * This function scrolls the page to the top without using useLayoutEffect.
 *
 * @function
 */
export function scrollStart() {
  const scrollToTop = () => {
    const c = window.pageYOffset;
    if (c > 0) {
      window.requestAnimationFrame(scrollToTop);
      window.scrollTo(0, c - c / 8);
    }
  };
  scrollToTop();
}

// * Get the last path name url
/**
 * Get the last path name from the current URL.
 * @returns {string} The last path segment of the URL.
 */
export const getTheLastPathName = () => {
  // Get the full pathname from window.location.pathname
  const fullPathname = window.location.pathname;

  // Split the pathname by '/' to get an array of path segments
  const pathSegments = fullPathname.split("/");

  // Filter out any empty segments
  const filteredPathSegments = pathSegments.filter((segment) => segment !== "");

  // Get the last path segment (the last part of the URL)
  const lastPathSegment = filteredPathSegments[filteredPathSegments.length - 1];

  return lastPathSegment;
};
