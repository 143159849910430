import { createSlice } from "@reduxjs/toolkit";

export const nightModeSlice = createSlice({
  name: "darkModeSlice",
  initialState: {
    nightMode: true,
    css: {
      bodyBackGroundColor: "#151515",
      headerTextColor: "header-Text-Night-Mode",
      textSoftColor: "text-Soft-Night-Mode",
      borderColorWith_Shadow_xl: "card-Border-Color-Shadow-xl-Night-Mode",
      borderColor: "border-Color-Night-Mode",
    },
  },
  reducers: {
    LightToggler: (state) => {
      if (state.nightMode) {
        state.nightMode = false;
        state.css.bodyBackGroundColor = "#ffffff";
        state.css.headerTextColor = "header-Text-Light-Mode";
        state.css.textSoftColor = "text-Soft-Light-Mode";
        state.css.borderColorWith_Shadow_xl =
          "card-Border-Color-Shadow-xl-Light-Mode";
        state.css.borderColor = "border-Color-Light-Mode";
      }
    },
    NightToggler: (state) => {
      if (!state.nightMode) {
        state.nightMode = true;
        state.css.bodyBackGroundColor = "#151515";
        state.css.headerTextColor = "header-Text-Night-Mode";
        state.css.textSoftColor = "text-Soft-Night-Mode";
        state.css.borderColorWith_Shadow_xl =
          "card-Border-Color-Shadow-xl-Night-Mode";
        state.css.borderColor = "border-Color-Night-Mode";
      }
    },
  },
});

export const { LightToggler, NightToggler } = nightModeSlice.actions;

export default nightModeSlice.reducer;
